import { atom } from "recoil";

export const InstitutesHomeCourses = atom({
  key: "InstitutesHomeCourses",
  default: {
    data: [],
    empty: true,
    next: null,
    prev: null,
  },
});
