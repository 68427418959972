import VerificationInput from "react-verification-input";
import "./Inputs.css";
import { trans } from "../../../../Components/Navbar/Navbar";
import axios from "axios";
import { basedUrl } from "../../../../Api/Apis";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorComponent, SuccsesComponent } from "../../../../Others/Error";
import { useRecoilState } from "recoil";
import {
  ForgetPasswordStates,
  verfyCodeRegister,
} from "../../../../RecoilState/ForgetPassword/ForgetPasswordStates";
import { use } from "i18next";
//const token = localStorage.getItem("token");

function Inputs({ route, type }) {
  const navigate = useNavigate();
  // phone number
  const [mobNumber, setMobNumber] = useState("");
  const [token, setToken] = useState("");
  useEffect(() => {
    setMobNumber(JSON.parse(localStorage.getItem("user"))?.mobile);
    setToken(localStorage.getItem("token"));
  }, []);
  // Validation State
  const [errorValidation, setErrorValidation] = useState({});
  // Validation State
  const [code, setCodse] = useRecoilState(verfyCodeRegister);
  // Function submit
  var formData;
  if (type === "register") {
    formData = {
      code: code,
      mobile_or_email: mobNumber,
    };
  } else {
    formData = {
      code: code,
    };
  }
  const submit = async (e) => {
    e.preventDefault();
    console.log("token:", token);

    try {
      const { data } = await axios.post(
        `${basedUrl}${route}`,
        { ...formData },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("data:", data);
      if (type !== "register") {
        console.log("the condition has applyed");
        localStorage.removeItem("token");
        localStorage.setItem("token", data.data.token);
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(data.data));
        navigate("/");
      } else {
        navigate("/register/newpassword");
      }
      SuccsesComponent(data.message);
    } catch (err) {
      err.message = "this Code is invalid";
      ErrorComponent(err, navigate, setErrorValidation);
    }
  };
  // Function submit
  return (
    <div className="Inputs full-width">
      <form
        onSubmit={submit}
        className="d-flex flex-column gap-3 gap-md-4 gap-lg-5">
        <VerificationInput
          length={6}
          passwordMode={true}
          validChars="A-Za-z0-9"
          classNames={{
            container: "container",
            character: "character",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
          onChange={(e) => {
            setCodse(e);
          }}
        />
        <button className="resetBtn p-3 r-10 fs-16-700" type="submit">
          {trans("verify.btn")}{" "}
        </button>
      </form>
    </div>
  );
}

export default Inputs;
