import { Link } from "react-router-dom";
import ArticleComponent from "../../../Home/Articles/ArticleComponent";
import "./AllArticle.css";
import Image from "./employ-meeting.jpg";
import { trans } from "../../../../Components/Navbar/Navbar";
import PaginationControll from "../../../../Components/PagenationControll";

function AllArticle({ all, getAllArticals }) {
  return (
    <div className="AllArticle py-5">
      <div className="container">
        <div className="row g-4">
          {all?.data?.map((item) => (
            <div className="col-12 col-md-6 col-lg-4" key={item.id}>
              <ArticleComponent item={item} />
            </div>
          ))}
        </div>

        <PaginationControll
          {...{
            next: all.next,
            prev: all.prev,
            getMoreData: () => getAllArticals(all.next),
            getLessData: () => getAllArticals(all.prev),
          }}
        />
      </div>
    </div>
  );
}

export default AllArticle;
