import React, { useState } from "react";
import AllArticle from "./Components/AllArticle/AllArticle";
import Landing from "./Components/Landing/Landing";
import axios from "axios";
import { apiHeaders, basedUrl } from "../../Api/Apis";
import { ErrorComponent } from "../../Others/Error";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
function AllArticles() {
  const navigat = useNavigate();

  // Get Article
  const [articles, setArticle] = useState({
    data: [],
    next: null,
    prev: null,
  });
  const [lastArticle, setLastArticle] = useState();

  const getAllArticals = (url) => {
    axios
      .get(url || `${basedUrl}/public/data/articles`, apiHeaders)
      .then((data) => {
        console.log("Articals=> ", data);
        setArticle({
          data: data.data.data.articles.data,
          next: data.data.data.articles.links.next,
          prev: data.data.data.articles.links.prev,
        });
        setLastArticle(data.data.data.last_article);
      })
      .catch((error) => {
        ErrorComponent(error, navigat);
      });
  };
  useEffect(() => {
    getAllArticals();
  }, []);
  return (
    <>
      {articles ? (
        <div className="AllArticles">
          <Landing last={lastArticle} />
          <AllArticle all={articles} getAllArticals={getAllArticals}/>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default AllArticles;
