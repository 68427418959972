import Welcom from "./Welcom";
import axios from "axios";
import { useEffect } from "react";
import { ErrorComponent } from "../../../../Others/Error";
import { basedUrl } from "../../../../Api/Apis";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { InstitutesHomeCourses } from "../../../../RecoilState/InstitutesHomeCourses";

function HomeCenter({ linkGetData }) {
  const token = localStorage.getItem("token");
  const navigat = useNavigate();
  const location = useLocation().pathname;
  // State Data
  const [corses, setCourses] = useRecoilState(InstitutesHomeCourses);
  // State Data
  const getCourses = (url) => {
    axios
      .post(
        url || `${basedUrl}${linkGetData}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        console.log(data);
        setCourses({
          data: data.data.courses.data,
          empty: data.data.empty,
          next: data.data.courses.links.next,
          prev: data.data.courses.links.prev,
        });
      })
      .catch((error) => {
        ErrorComponent(error, navigat);
      });
  };
  useEffect(() => {
    getCourses();
  }, [location]);
  return (
    <Welcom
      linkGetData={linkGetData}
      getData={getCourses}
      setCourses={setCourses}
    />
  );
}

export default HomeCenter;
