import { atom } from "recoil";

export const ForgetPasswordStates = atom({
  key: "ForgetPasswordStates",
  default: "",
});

export const verfyCodeRegister = atom({
    key:'verfyCodeRegister',
    default:""
})