import { trans } from "../Navbar/Navbar";

const buttonStyle = {
  width: "100px",
};

export default function PaginationControll({
  next,
  prev,
  getMoreData,
  getLessData,
}) {
  return (
    <>
      {(next || prev) && (
        <div
          className={`button d-flex ${
            next && !prev
              ? "justify-content-start"
              : !next && prev
              ? "justify-content-end"
              : "justify-content-between"
          } mt-5`}>
          {next && (
            <button
              onClick={getMoreData}
              className="bg-main flex-c py-3 r-10 text-white"
              style={buttonStyle}>
              {trans("flyers.next")}
            </button>
          )}

          {prev && (
            <button
              onClick={getLessData}
              className="bg-main flex-c py-3 r-10 text-white"
              style={buttonStyle}>
              {trans("flyers.prev")}
            </button>
          )}
        </div>
      )}
    </>
  );
}
