import axios from "axios";
import React from "react";
import { basedUrl } from "../../../Api/Apis";
import { ErrorComponent, SuccsesComponent } from "../../../Others/Error";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import WelcomNoAdJobs from "../../../Components/WelcomNoAdJobs/WelcomNoAdJobs";
import ProjectCard from "./ProjectCard/ProjectCard";
import { trans } from "../../../Components/Navbar/Navbar";
import PagenationControll from "../../../Components/PagenationControll";
// WelcomNoAdJobs
const WelcomData = {
  header: trans("business_pioneer.WelcomData.header"),
  startNow: trans("business_pioneer.WelcomData.startNow"),
  hent: trans("business_pioneer.WelcomData.hent"),
  linkTitle: trans("business_pioneer.WelcomData.linkTitle"),
  linkTo: "/business_pioneer/dashboard/add-project",
};

function AllProjectes() {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigat = useNavigate();
  const [projects, setProjects] = useState({
    data: [],
    next: null,
    prev: null,
  });
  const getData = (url) => {
    axios
      .get(url || `${basedUrl}/business-pioneer/projects`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        console.log("data come from all projects=> ", data);
        setProjects({
          data: data.data.data,
          next: data.data.next,
          prev: data.data.prev,
        });
      })
      .catch((error) => {
        ErrorComponent(error, navigat);
      });
  };
  useEffect(() => {
    if (user?.data_status !== "waiting") {
      getData();
    }
  }, []);
  return (
    <>
      {projects.data.length > 0 ? (
        <>
          <h3 className="mb-4">{trans("business_pioneer.all_projects")}</h3>
          <div className="row g-4">
            {projects.data?.map((item) => (
              <div className="col-12 col-md-6" key={item.id}>
                <ProjectCard
                  item={item}
                  link={`/business_pioneer/dashboard/project-edit/${item.id}`}
                />
              </div>
            ))}

            <PagenationControll
              next={projects.next}
              prev={projects.prev}
              getMoreData={() => getData(projects.next)}
              getLessData={() => getData(projects.prev)}
            />
          </div>
        </>
      ) : (
        <WelcomNoAdJobs {...WelcomData} />
      )}
    </>
  );
}

export default AllProjectes;
